@import '../reset/fonts'
@import '../reset/vars'
@import '../reset/helpers'

.siteHeader
	&__logo
		opacity 0
		transform: translateY(-30px)
		display block

.menu
	opacity 0
	transform: translateY(-30px)
	display block

.homeHero
	width: 100vw;
	overflow: hidden;
	padding-top 0

	@media desktop
		min-height 100vh

	&--inner
		width: 100%;
		height: 100%;
		position: relative;
		padding: 130px 0 0 0
		display flex
		flex-direction: column
		align-items: center
		justify-content: center

		@media tablet
			padding 0

		@media desktop
			min-height: 100vh
			// padding 200px 0
			transform: translateY(0)


	&__title
		color: white;
		width: 100%
		max-width 1300px
		margin: 0 auto;
		padding: 0 20px;
		bold()
		font-size: 6rem;
		line-height: 110%;
		text-align: center;
		text-transform: uppercase;
		z-index 9
		overflow hidden
		opacity 0
		cursor default
		position relative

		@media desktop
			color transparent
			-webkit-text-stroke: 2px white;

		div
			opacity 0
			transform: translateY(30px)

			@media desktop
				transition .5s color ease

				&:hover
					color: #fff
					-webkit-text-stroke: 2px #000

		&.inView
			opacity 1

			div
				opacity 1
				transform: translateY(0)
				transition: opacity 1.5s ease, transform 1.5s ease, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(1)
					transition: 1.5s opacity ease .5s, 1.5s transform ease .5s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(2)
					transition: 1.5s opacity ease .6s, 1.5s transform ease .6s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(3)
					transition: 1.5s opacity ease .7s, 1.5s transform ease .7s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(4)
					transition: 1.5s opacity ease .8s, 1.5s transform ease .8s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(5)
					transition: 1.5s opacity ease .9s, 1.5s transform ease .9s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(6)
					transition: 1.5s opacity ease 1s, 1.5s transform ease 1s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(7)
					transition: 1.5s opacity ease 1.1s, 1.5s transform ease 1.1s, .5s color ease, .5s -webkit-text-stroke ease

				&:nth-child(8)
					transition: 1.5s opacity ease 1.2s, 1.5s transform ease 1.2s, .5s color ease, .5s -webkit-text-stroke ease

		@media desktop
			font-size 15rem
			line-height 15rem
			text-align: center

		@media wide
			font-size 19rem
			line-height 19rem

	&__clips
		width 200px
		height 200px
		margin-bottom 35px
		object-fit: cover
		display block
		clip-path: polygon(50% 0%, 99% 46%, 100% 100%, 70% 100%, 70% 67%, 30% 67%, 30% 100%, 0 100%, 0 46%);
		z-index 10
		mix-blend-mode: exclusion

		@media tablet
			// display none
			width 70vh
			height 70vh

.aboutIntro
	padding: 50px 0 20px 0

	@media desktop
		padding 0 0 100px 0

	&--inner
		padding: 0 30px

	&__copy
		width: 100%
		max-width 830px
		light()
		font-size 2.2rem
		line-height 2.9rem

		@media tablet
			font-size 4rem
			line-height 5rem

		@media desktop
			font-size 5rem
			line-height 6.6rem
			margin 0 auto

		p
			text-align: center

		a
			color pink
			font-style: normal
			position relative
			text-decoration: none;
			background-image: linear-gradient(pink, pink);
			background-position: 0% 100%;
			background-repeat: no-repeat;
			background-size: 0% 1px;

			@media desktop
				transition: .35s cubic-bezier(.77,.01,.18,1)

				&:hover
					background-size: 100% 1px;
					transition: .65s cubic-bezier(.77,.01,.18,1)

#reelPopupWrap
	@media desktop
		display block !important

.reel
	position relative
	width: 100%;
	height: auto
	display flex
	align-items: center
	justify-content: center

	&.nonTouch
		min-height: 100vh

	@media desktop

		&:hover
			.playPauseVideo
				opacity 0

	&--playing
		.reel__bRoll
			opacity 0
			z-index 1
			transition: opacity .35s, z-index 0s .35s

		.reel__videoInfo
			opacity 0

	&__backgroundVideo
		position relative
		width: 90%
		height auto
		margin: 0 auto

		@media desktop
			transition: width .15s, height .15s

	&__fullVideo
		width: 100%
		height auto

		@media desktop
			width: 100%
			height 100%
			position absolute
			top 0
			left: 0
			z-index 2

	&__bRoll
		width: 100%
		height 100%
		position: relative
		z-index: 3
		opacity: 1
		transition: opacity .35s, z-index 0s 0s

	&__placeholderPicture
		position: absolute
		top 0
		left 0
		z-index: 4

	&__videoInfo
		position: absolute
		bottom: 12px
		left: 26px
		z-index: 5
		opacity: 1
		transform: opacity .35s

		@media desktop
			bottom 100px
			left 5%
			opacity 0

		@media wide
			left calc((100vw - 1200px) / 2)

	&__title
		color black
		// color white
		bold()
		font-size: 1rem
		line-height: .9rem
		text-transform: uppercase

		@media tablet
			font-size: 1.7rem
			line-height: 1.6rem

	&__playPauseVideo
		background: url('../../images/icon-play.png') no-repeat
		background-size: 25px 28.98550725px
		width: 25px
		height: 28.98550725px
		text-indent: -9999px
		margin-bottom 22px
		display: block
		opacity 1
		transition: .25s

		@media desktop
			background-size: 38px 44.05797102px
			width: 38px
			height: 44.05797102px
			margin-bottom: 36px

.latestNews

	&__descriptorCTA
		color gray
		padding-right 20px
		text-transform: uppercase
		regular()
		font-size 1rem
		line-height 1rem
		position relative
		display block
		text-align: right

		@media tablet
			background-size: 22px 14.99659168px
			padding-right: 35px
			font-size 1.2rem
			line-height 1.2rem

			&:hover
				color gray

				&:after
					transform: translateX(5px)

		&:after
			content ''
			background: url('../../images/arrow-right-pink.png') no-repeat 100% center
			background-size: 14.67px 10px
			width: 14.67px
			height 10px
			position absolute
			top -2px
			right: 0

			@media tablet
				background-size: 22px 14.99659168px
				width 22px
				height 14.99659168px
				transition: .5s ease

		&:before
			content ''
			background: white
			width: 0
			height: 1px
			display block
			position absolute
			bottom: -5px
			transition: .35s cubic-bezier(.77,.01,.18,1)

		@media desktop
			transition: .35s

			&:hover
				opacity 1

				&:before
					width: 70px
					left: 0
					right: auto
					transition: .5s cubic-bezier(.77,.01,.18,1)

	&__articles
		@media desktop
			max-width 1200px
			margin 0 auto

		@media desktop
			display flex
			justify-content: space-between

	&__articleWrap
		width 100%

		@media desktop
			width 47%

	&__article
		color #eee
		width: 100%
		margin-bottom 47px
		padding: 220px 20px 20px 20px
		display: block
		position: relative

		@media tablet
			padding-top 350px

		@media desktop
			margin-bottom 0
			padding 430px 35px 35px 35px

			&:hover
				color #eee

				.latestNews__thumbnail
					transform: scale(1.1)

				.latestNews__category
					transform: translateY(-15px)
					transition: .5s ease

				.latestNews__title
					transform: translateY(-15px)
					transition: .5s ease .08s

				.latestNews__cta
					transform: translateY(-15px)
					transition: .5s ease .16s

		&:after
			content ''
			background: rgba(0,0,0,.5)
			background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(255,255,255,0) 70%);
			width: 100%
			height: 100%
			display: block
			position: absolute
			bottom: 0
			left: 0
			z-index: 2

			@media desktop
				transition: .5s ease

		&:before
			@media desktop
				content ''
				background: url('../../images/bg-wave.png') no-repeat
				background-size: 507px 510px
				width: 507px
				height 510px
				display block
				position absolute
				bottom: -60px
				left -60px

		// &.inView
		// 	.latestNews__category
		// 		opacity 1
		// 		transform: translateY(0)
		// 		transition: .5s ease .5s

		// 	.latestNews__title
		// 		opacity 1
		// 		transform: translateY(0)
		// 		transition: .5s ease .6s

		// 	.latestNews__cta
		// 		opacity 1
		// 		transform: translateY(0)
		// 		transition: .5s ease .7s

		// 	.latestNews__thumbnail
		// 		opacity 1
		// 		transform: scale(1)
		// 		transition: .5s ease .1s

		&--inner
			height 100%
			overflow hidden
			display block

		&--smallThumb
			padding: 0

			@media desktop
				padding 430px 35px 35px 35px

				&:before
					display none

			&:after
				opacity 0

			.latestNews__category
				margin-top 10px

			.latestNews__info
				padding: 20px

				@media desktop
					padding: 0

			.latestNews__thumbnail

				@media desktop
					height 400px

			.latestNews__thumbWrap
				position relative

				@media desktop
					position absolute

	&__info
		position: relative
		z-index: 3

		@media tablet
			width: 60%

		@media desktop
			width: 100%

	&__category
		font-size: 1rem
		text-transform: uppercase
		display: block
		margin-bottom: 16px
		regular()
		transition: .5s ease
		// opacity 0
		// transform: translateY(30px)

		@media tablet
			font-size 1rem
			// will-change: transform
			// transition: .5s ease .16s

	&__title
		margin-bottom: 15px
		font-size: 2.2rem
		line-height: 2.5rem
		semi-bold()
		transition: .5s ease .08s
		// opacity 0
		// transform: translateY(30px)

		@media tablet
			font-size 3rem
			line-height 3.5rem

		@media desktop
			font-size 3.5rem
			line-height 3.9rem
			// will-change: transform
			// transition: .5s ease .08s

	&__cta
		regular()
		font-size: 1.2rem
		line-height: 1.2rem
		// opacity 0
		// transform: translateY(30px)

		@media tablet
			font-size 1.5rem
			line-height 1.7rem
			// will-change: transform
			// transition: .5s ease 0s

	&__thumbWrap
		width: 100%
		height 100%
		display block
		position: absolute
		top 0
		left 0
		z-index 1
		overflow hidden

	&__thumbnail
		width 100%
		height: 100%
		object-fit: cover
		object-position: top center
		transform: scale(1.15)
		transform-origin: center center
		will-change: transform
		transition: .5s ease

.featuredWork
	padding-top: 0

	@media desktop
		padding-bottom 0

	&__play
		background: url('../../images/icon-play-white.png') no-repeat center center
		background-size: 25px 28.98550725px
		width: 50px
		height: 50px
		display: block
		position absolute
		top 50%
		left 50%
		transform: translate(-50%, -50%)
		z-index 9
		text-indent: -9999px
		border 1px solid white
		border-radius: 50px

		@media desktop
			width: 80px
			height: 80px

	&__status
		color pink
		margin-bottom 0
		bold()
		font-size: 1.4rem
		line-height 1.3rem
		text-transform: uppercase

	&__slider
		width: 100%
		height: 449px

		@media desktop
			height 650px

	&__slide
		width: 80%
		height: 449px
		overflow: hidden;
		opacity: .3
		transition: opacity 1s ease
		z-index: 4

		@media desktop
			height: 650px

		&:after
			content: ''
			background rgba(black,.2)
			background: linear-gradient(180deg, rgba(black, 0) 0%, rgba(black, .8) 100%);
			width: 100%
			height 100%
			display block
			position absolute
			top 0
			left 0
			z-index 1

		&.is-selected
			opacity: 1
			z-index: 5

			.featuredWork__title
				transform: translateX(0)
				transition: transform .5s ease .35s

			.featuredWork__logo
				transform: translateX(0)
				transition: transform .5s ease .45s

			.featuredWork__clientName
				transform: translateX(0)
				transition: transform .5s ease .55s

	&__bgImage
		display: block
		height: 650px
		width: 100%
		object-fit: cover

	&__infoWrap
		padding: 0 20px
		position absolute
		bottom 30px
		z-index 5

		@media desktop
			padding: 0 100px
			bottom 60px

			@media desktop
					&:hover
						.featuredWork__title,.featuredWork__client
							color pink
							transition: .25s

	&__title
		color white
		margin-bottom 10px
		regular()
		font-size 3.5rem
		line-height 4rem
		text-transform: uppercase
		transform: translateX(30px)

		@media desktop
			font-size 7.5rem
			line-height 7.5rem
			transition: .25s

	&__client
		color white
		display flex
		align-items: center

		@media desktop
			transition: .25s

	&__logo
		width 33px
		margin-right 14px
		transform: translateX(30px)

		@media desktop
			width 41px

	&__clientName
		font-size 1.2rem
		regular()
		transform: translateX(30px)

		@media desktop
			font-size 1.5rem

	&__video
		display block
		width 100%
		height auto
		object-fit: cover

.inlineNewsletter
	width: 90%
	margin 0 auto
	max-width: 650px

	.mc4wp-form
		position relative
		margin-bottom 60px

		.form__group
			position relative

		.form__label
			display none

		.form__input
			margin-bottom 0

			@media tablet-large
				margin-bottom 30px

			&::-webkit-input-placeholder
				font-size 1.4rem

				@media tablet-large
					font-size 1.6rem

		.form__submit
			background none
			border none
			color pink
			text-transform: uppercase
			bold()
			margin 0 auto
			width auto
			font-size 1.6rem

			@media tablet-large
				position absolute
				top 0
				right 5px

	.mc4wp-notice,.mc4wp-error
		border 2px dashed pink
		color pink
		padding: 15px

		p
			margin 0
			text-align: center
			font-size  1.6rem

	.mc4wp-success
		// border 1px solid green
		padding: 15px
		color green
		font-size 1.6rem

	p
		margin-bottom 0
		text-align: center