light()
  font-family: proxima-nova,sans-serif;
  font-weight: 300;
  font-style: normal;

regular()
  font-family: proxima-nova,sans-serif;
  font-weight: 400;
  font-style: normal;

semi-bold()
  font-family: proxima-nova,sans-serif;
  font-weight: 600;
  font-style: normal;

bold()
  font-family: proxima-nova,sans-serif;
  font-weight: 700;
  font-style: normal;

@font-face
  font-family 'tiempos'
  src: url(../../fonts/tiempos.woff2) format('woff2')